import React, { useEffect } from 'react'
import Header from '../Header/Header'
import { useState } from 'react'
import axiosInstance from '../../helpers/request';
import Cookies from 'universal-cookie';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CreatableSelect from "react-select/creatable"
import Swal from "sweetalert2";
import Select from "react-select"
import { toast } from 'react-toastify';
import { monthlyPaymentArray } from '../../helpers/paymentArray';
import SubmitLoader from '../common/SubmitLoader';
import { useAppState } from '../../state';
import { permissions } from '../../helpers/permissions';
import { Autocomplete, TextField } from '@mui/material'
import Delete from '../../images/circle-cross.svg';
import "./createUser.css";

const services = [
    { label: 'Fire Safety', value: 'fireSafety' },
    { label: 'Food Safety', value: 'foodSafety' },
    // { label: 'Safety Management', value: 'safetyManagement' },
    { label: 'Safety Training', value: 'safetyTraining' },
    { label: 'Remote Working', value: 'remoteWorking' },
    { label: 'SMS Safety', value: 'smsSafety' },

];

function CreateUser({ title }) {
    const cookies = new Cookies();
    const { user: userDetails, userPermissions } = useAppState()
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()
    const isProfile = location.pathname.includes("profile")
    const isEdit = location.pathname.includes("edit-user") || isProfile
    const userToken = localStorage.getItem("cs_token");
    const [newContractor, setNewContractor] = useState("")
    const [user, setUser] = useState("")
    const [emailPreference, setEmailPref] = useState(permissions.map(v => v.children).flat().map(v => ({ name: v.name, value: false })))
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        role: (userDetails.role == "superadmin" ? ["admin"] : []),
        emailPreferences: "",
        added_by: "",
        addedByInCompany: "",
        // chosenServices: "",
        companyName: userDetails.companyName?userDetails.companyName:"",
        contractor: "",
        subscription_id: "",
        trial: "",
    })
    const [roles, setRoles] = useState([])
    const [contractors, setContractors] = useState([])
    const [loading, setLoading] = useState(false)
    const [newSitePopup, setNewSitePopup] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [siteOptions, setSiteOptions] = useState([]);
    const [departmentOptions, setdDepartmentOptions] = useState([]);
    const [newSite, setNewSite] = useState([]);
    
    const [sites, setSites] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [userDepartments, setUserDepartments] = useState([])
    const [userSites, setUserSites] = useState([]);
    
    const [department, setDepartment] = useState([]);
    const [allDepartments, setAllDepartments] = useState([]);
    const [selectedSite, setSelectedSite] = useState("")
    const [selectedDepartment, setSelectedDepartment] = useState("")
    const [selectedRole, setSelectedRole] = useState("")
    const [selectedDRole, setSelectedDRole] = useState("")

    const siteRoles = [
      {label: 'Admin', value: 'admin'},
      {label: 'User', value: 'user'}
    ];

    const departmentRoles = [
      {label: 'Admin', value: 'admin'},
      {label: 'User', value: 'user'}
    ];

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const getSites = () => {
        if(user) {
        axiosInstance
          .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${user.id}`, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            if (res.data && res.data.length) {
              let allSites = res.data.filter((v, i) => res.data.findIndex(v1 => v1.id == v.id) == i).map(v => ({ ...v, label: v.name }))
              setSites(allSites);
              getDepartments(allSites)
            }
          })
          .catch((err) => {
            console.log("errr", err);
          });
        }
    };

    useEffect(() => {
      if (sites.length) {
        setSiteOptions(
          sites.map((d) => ({
            label: d.name,
            value: d.id,
            name: "siteId",
          }))
        );
      }
    }, [sites]);


    useEffect(() => {
      if (newSite.siteId) {
        setdDepartmentOptions(
          allDepartments
            .filter((v) => v.siteId == newSite.siteId)
            .map((d) => ({
              label: d.name,
              value: d.id,
              name: "departmentId",
            }))
        );
      }
    }, [newSite]);

    const getDepartments = (allSites) => {
        if(companyId) {
            axiosInstance
            .post(
              `${process.env.REACT_APP_API_BASEURL}/all-departments`,
              {
                companyId: companyId,
                status: "",
              },
              {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              }
            )
            .then((res) => {
              if (res.data.length > 0) {
                setDepartments(res.data.filter((v, i) => res.data.findIndex(v1 => v1.id == v.id) == i).map(v => ({ ...v, label: `${v.name} - ${allSites.filter(s => s.id == v.siteId)[0]?.label}` })));
              }
            })
            .catch((err) => {
              console.log("errr", err);
            });
        }
      };


    useEffect(() => {
        userDetail();        
        if (isEdit && (id || isProfile)) {
            getUser()
        }
    }, [])

    useEffect(() => {
        getSites();
        if (user && user.companyId) {
            getContractors(user.companyId);
        }
        getUsersSites();
        getUsersDepartments();
    }, [user])

    function userDetail() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setCompanyId(
                        res.data[0].added_by ? res.data[0].added_by : res.data[0].id
                      );

                    // Check if the user is admin
                    if ( res.data[0].role !== 'superadmin' && res.data[0].role !== 'admin' && ! userPermissions.includes('createUser') && ! isProfile ) {
                        history.push('/');
                    }

                    setUser({ ...res.data[0], companyId: ((res.data[0].role !== "admin") && res.data[0].added_by) ? res.data[0].added_by : res.data[0].id });
                    if (!isEdit) {
                        if (res.data[0].role == "admin") {
                            setData({
                                ...data,
                                // chosenServices: res.data[0].chosenServices,
                                companyName: res.data[0].companyName,
                                subscription_id: res.data[0].subscription_id,
                                trial: res.data[0].trial,
                                added_by: ((res.data[0].role !== "admin") && res.data[0].added_by) ? res.data[0].added_by : res.data[0].id,
                                addedByInCompany: res.data[0].id
                            })
                        } else {
                            const item = monthlyPaymentArray[0]
                            let expireDate;
                            const currentDate = new Date();
                            if (item.validity === "yearly") {
                                expireDate = new Date(
                                    currentDate.setFullYear(currentDate.getFullYear() + 1)
                                );
                            } else {
                                expireDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
                            }
                            const expiryTimestamp = Math.floor(expireDate.getTime() / 1000);
                            setData({
                                ...data,
                                added_by: res.data[0].id,
                                trial: `${expiryTimestamp}-${item.priceId}`,
                                addedByInCompany: res.data[0].id
                            })
                        }
                    }
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    function getUser() {
        axiosInstance
            .get(`${process.env.REACT_APP_API_BASEURL}/user/${isProfile ? userDetails.id : id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.id) {
                    setData({
                        ...res.data,
                        contractor: res.data.contractorId ? res.data.contractorId : "",
                    });
                    getUsersSites()
                    getUsersDepartments()
                    if (!res.data.role.includes('admin')) {
                        axiosInstance.get(`${process.env.REACT_APP_API_BASEURL}/permissions/${res.data.role}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${userToken}`,
                                },
                            }
                        ).then((res1) => {
                            if (res1.data.length > 0) {
                                let allPermits = res1.data
                                    .map((v) => v.permissions)
                                    .join(",")
                                    .split(",");
                                console.log("allPermits", allPermits, res.data.emailPreference)
                                if (res.data.emailPreference) {
                                    setEmailPref(
                                        permissions
                                            .map((v) => v.children)
                                            .flat()
                                            .filter(v => allPermits.includes(v.name))
                                            .map(v => ({ name: v.name, value: res.data.emailPreference.split(",").includes(v.name) }))
                                    )
                                } else {
                                    setEmailPref(
                                        permissions
                                            .map((v) => v.children)
                                            .flat()
                                            .filter(v => allPermits.includes(v.name))
                                            .map(v => ({ name: v.name, value: true }))
                                    );
                                }
                            }
                        }).catch((err) => {
                            console.log("errr", err);
                        });
                    } else {
                        setEmailPref(
                            permissions
                                .map((v) => v.children)
                                .flat()
                                .map(v => ({ name: v.name, value: res.data.emailPreference ? res.data.emailPreference.split(",").includes(v.name) : true }))
                        )
                    }
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const getUsersSites = () => {
      axiosInstance
          .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${isProfile? user.id : id}`, {
              headers: {
                  Authorization: `Bearer ${userToken}`,
              },
          })
          .then((res) => {
              if (res.data && res.data.length) {
                  setUserSites(res.data)
              }
          })
          .catch((err) => {
              console.log("errr", err);
          });
  }

    const getUsersDepartments = () => {
      axiosInstance
          .get(`${process.env.REACT_APP_API_BASEURL}/users-department/${isProfile? user.id : id}`, {
              headers: {
                  Authorization: `Bearer ${userToken}`,
              },
          })
          .then((res) => {
              if (res.data && res.data.length) {
                  setUserDepartments(res.data)
              }
          })
          .catch((err) => {
              console.log("errr", err);
          });
  }

    useEffect(() => {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/all-roles`, {
                companyId: user.companyId,
                status: ""
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setRoles(res.data);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }, [user])

    const isValidData = () => {
        const { firstName, lastName, email, role } = user;
        const EMAIL_REGEX = new RegExp(/\S+@\S+\.\S+/);
        let isValid = true;
        let message = "";

        if (firstName === "" || lastName === "" || email === "" || role === "") {
            isValid = false;
            message = "All fields are mandatory";
        } else if (!EMAIL_REGEX.test(email)) {
            isValid = false;
            message = "Please enter valid email address";
        }
        // else if (password !== cpassword) {
        //   isValid = false;
        //   message = "Password and Confirm Password not matched";
        // }

        return { isValid, message };
    };

    const handleSubmit = () => {
        const { isValid, message } = isValidData();
        if (isValid) {
            setLoading(true)
            if (newContractor) {
                axiosInstance.post('/add-contractor', {
                    name: newContractor,
                    description: newContractor,
                    companyId: user.companyId,
                }, {
                  headers: {
                      Authorization: `Bearer ${userToken}`,
                  },
                }).then(async (response) => {
                    if (response.status === 200 && response.data.insertId !== "undefined") {
                        addUpdateUser(response.data.insertId)
                    } else {
                        toast.error("Failed to add contractor")
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log(err)
                    setLoading(false)
                    toast.error("Failed to add contractor")
                });
            } else {
                addUpdateUser()
            }
        } else {
            toast.error(message);
        }
    }


    const handleEmailPreference = () => {
        setLoading(true)
        axiosInstance.post('/update-email-preference', {
            userId: isProfile ? userDetails.id : id,
            emailPreference: emailPreference.filter(v => v.value).map(v => v.name).join(",")
        }, {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        }).then(async (response) => {
            if (response.status === 200 && response.data.insertId !== "undefined") {
                // addUpdateUser(response.data.insertId)
                toast.success("Succesfully Update Email Preferences")
            } else {
                toast.error("Failed to Update Email Preferences")
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
            toast.error("Failed to Update Email Preferences")
        });
    }

    const handlePasswordReset = async (id, name, email) => {
        const result = await Swal.fire({
            title: `Do you really want to send password reset for:`,
            text: `${name}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, send email!",
        });

        if (result.isConfirmed) {
            axiosInstance
                .post(process.env.REACT_APP_API_BASEURL + "/verify-email/", {
                    email: email
                })
                .then(function (response) {
                    toast.success("Email sent successfully");
                })
                .catch(function (error) {
                    toast.error(error?.response?.data?.message || "Unable to send email user");
                });
        }
    };

    const getContractors = (companyId) => {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/all-contractors`, {
                companyId: companyId,
                status: 1
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setContractors(res.data);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const addUpdateUser = (contractor) => {

      const fieldsToCheck = {
        firstName: "First Name is Required",
        lastName: "Last Name is Required",
        email: "Email Address is Required",
      }

      for (let field in fieldsToCheck) {
        if (!data[field]) {
          toast.error(fieldsToCheck[field]);

          // focus field
          document.querySelector(`[name=${field}]`).focus();

          setLoading(false);
          return;
        }
      }

      if ( ! data.role || data.role.length == 0 ) {
        toast.error("Role is required.");
        setLoading(false);
        return;
      }

      if ( ! isProfile ) {
        // User Sites Must be Present
        if ( ! userSites || userSites.length == 0 ) {
          toast.error("Please add at least one site to profile.");
          setLoading(false);
          return;
        }
        
        // DISALBED DEPARTMENTS CHECK
        /* if ( ! userDepartments || userDepartments.length == 0 ) {
          toast.error("Please add at least one department to profile.");
          setLoading(false);
          return;
        } */
      }

      if (isEdit) {

          if ( ! data.role || data.role.length == 0 ) {
              toast.error("Role is required.");
              setLoading(false);
              return;
          }

          axiosInstance.put(`/update-user/${isProfile ? userDetails.id : id}`, {
              ...data,
              ...(contractor ? { contractor } : {}),
              userSites,
              userDepartments
          }, { headers: { Authorization: `Bearer ${userToken}` } }).then(async (response) => {
              if (response.status === 200) {
                  // setData(response.data)
                  toast.success("Successfully Updated user")
                  !isProfile ?? history.push("/users")
              } else {
                  throw new Error(response?.error);
              }
              setLoading(false)
          }).catch(err => {
              console.log(err)
              setLoading(false)
              toast.error("Failed to Update user")
          });
      } else {
          axiosInstance
              .post("/user", { 
                data: { ...data, ...(contractor ? { contractor } : {}), 
                role: data.role.join(","), 
                companyId: ( user.role == 'admin' ? user.id : user.companyId) }, 
                actionBy: user.firstName + " " + user.lastName,
                userSites,
                userDepartments ,
                emailPreferences: emailPreference.map(v => v.name).join(",")
              })
              .then(function (response) {
                  setLoading(false);
                  toast.success("User added successfully");
                  !isProfile ?? history.push("/users");
              })
              .catch(function (error) {
                  setLoading(false);
                  // already exists in our records
                  if (error?.response?.data?.message.email !== "" && error?.response?.data?.message.companyName !== "") {
                      if (error?.response?.data?.message.email === data.email && error?.response?.data?.message.companyName === data.companyName) {
                          return toast.error(
                              ` Email and CompanyName already exists in our records` || "Unable to process this request"
                          )
                      } else if (error?.response?.data?.message.email.trim() === data.email.trim()) {
                          return toast.error(
                              ` Email already exists in our records` || "Unable to process this request"
                          )
                      } else {
                          return toast.error(
                              error?.response?.data?.message || "Unable to process this request"
                          )
                      }
                  } else {
                      toast.error("Unable to process this request");
                  }
              });
      }
    }


    const handleDeleteSite = (id, name) => {
      if(userSites.length) {
        const filteredSites = userSites.filter(obj => obj.id !== id);
        setUserSites(filteredSites);
      }
    }

    const addSiteUser = () => {
      let newSite = {
          userid: id,
      };
      if (selectedRole?.value && selectedSite) {
          newSite.type = selectedRole.value;
          newSite.siteid = selectedSite.id;
          newSite.name = selectedSite.name;
          newSite.id = selectedSite.id;
          newSite.description = selectedSite.description;
          setUserSites([
            ...userSites,
            newSite
          ]);
          setSelectedRole("");
          setSelectedSite("");
      } else {
          toast.error("Please select site and role")
      }
  }

    const addDepartmentUser = () => {
      let newDepartment = {
          userid: id
      };
      if (selectedDRole?.value && selectedDepartment) {
        newDepartment.type = selectedDRole.value;
          newDepartment.departmentid = selectedDepartment.id
          newDepartment.id = selectedDepartment.id
          newDepartment.name = selectedDepartment.name
          newDepartment.description = selectedDepartment.description
          setUserDepartments([
            ...userDepartments,
            newDepartment
          ]);
          setSelectedDepartment("");
          setSelectedDRole("");
      } else {
          toast.error("Please select department and role")
      }
  }

    const handleDeleteDepartment = async (departmentId, departmentName) => {
      const result = await Swal.fire({
          title: `Are you sure you want to remove ${data.firstName} ${data.lastName} from ${departmentName}`,
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        if(userDepartments.length) {
          const filteredDepartments = userDepartments.filter(obj => obj.id !== departmentId);
          setUserDepartments(filteredDepartments);
        }
      }
  };

    console.log((data.role.includes("admin")) , isProfile , ((userDetails.id == id)),userDetails)


    return (
      <div class="middle_page">
        <Header
          title={title}
          steps={[
            { name: "Home", path: "/" },
            {
              name: isEdit
                ? "Edit User"
                : isProfile
                ? "My Profile"
                : "Add User",
              path: "/create-user",
            },
          ]}
        />
        <div class="main_container">
          <div>
            <div class="accident">
              <div class="accident_text">
                <h5>{!isEdit ? "Add User" : "Edit User"}</h5>
              </div>
            </div>
            <form>
              <div class="form-1">
                <div class="form justify-content-start row">
                  <div class="col-6 form_inner p-2">
                    <label for="">
                      First Name <span className="text-danger">*</span>{" "}
                    </label>
                    <div class="input_icons">
                      <div class="input_field">
                        <input
                          type="text"
                          value={data.firstName}
                          name="firstName"
                          onChange={handleChange}
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6 form_inner p-2">
                    <label for="">
                      Last Name <span className="text-danger">*</span>{" "}
                    </label>
                    <div class="input_icons">
                      <div class="input_field">
                        <input
                          type="text"
                          value={data.lastName}
                          name="lastName"
                          onChange={handleChange}
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6 form_inner p-2">
                    <label for="">
                      Email Address <span className="text-danger">*</span>{" "}
                    </label>
                    <div class="input_icons">
                      <div class="input_field">
                        <input
                          type="text"
                          disabled={
                            isEdit && !userDetails.role.includes("admin")
                          }
                          value={data.email}
                          name="email"
                          onChange={handleChange}
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                  </div>
                  {user.role == "superadmin" ? (
                    <div class="col-6 form_inner p-2">
                      <label for="">Company Name </label>
                      <div class="input_icons">
                        <div class="input_field">
                          <input
                            type="text"
                            value={data.companyName}
                            disabled={
                              (userDetails.id == id || isProfile) &&
                              userDetails.role.includes("admin")
                            }
                            name="companyName"
                            onChange={handleChange}
                            placeholder="Company Name"
                          />
                        </div>
                      </div>
                    </div>
                  ) : data.role != "admin" ? (
                    <div class="col-6 form_inner p-2">
                      <label for="">Contractor </label>
                      <div class="input_icons">
                        <div class="w-100">
                          <CreatableSelect
                            value={
                              newContractor
                                ? [
                                    {
                                      label: newContractor,
                                      value: newContractor,
                                    },
                                  ]
                                : contractors
                                    .map((d) => ({
                                      label: d.name,
                                      value: d.id,
                                    }))
                                    .filter((v) => v.value == data.contractor)
                            }
                            options={(newContractor
                              ? [{ label: newContractor, value: newContractor }]
                              : []
                            ).concat(
                              contractors.map((d) => ({
                                label: d.name,
                                value: d.id,
                              }))
                            )}
                            onCreateOption={(v) => setNewContractor(v)}
                            placeholder="Select Contractor"
                            onChange={(v) => {
                              // console.log(v)
                              setNewContractor("");
                              setData({
                                ...data,
                                contractor: v.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div class="col-6 form_inner p-2">
                    <label for="">
                      Role <span className="text-danger">*</span>{" "}
                    </label>
                    <div class="input_icons">
                      <div class="w-100">
                        <Select
                          isMulti
                          options={roles.map((d) => ({
                            label: d.name,
                            value: d.id,
                            rolePermissions: d.permissions,
                          }))}
                          isClearable={
                            !isEdit ||
                            (isEdit && userDetails.role.includes("admin"))
                          }
                          isDisabled={
                            data.role.includes("admin") ||
                            isProfile ||
                            userDetails.id == id
                          }
                          value={
                            data.role == "admin"
                              ? [{ label: "Admin", value: "admin" }]
                              : roles
                                  .filter((v) => data.role.includes(v.id))
                                  .map((v) => ({
                                    label: v.name,
                                    value: v.id,
                                    rolePermissions: v.permissions,
                                  }))
                          }
                          placeholder="Select Roles"
                          onChange={(v) => {
                            // Loop through roles to find the selected id
                            if (
                              v &&
                              v.length &&
                              (!isEdit || userDetails.role.includes("admin"))
                            ) {
                              let selectedRolePermissions = roles.filter(
                                (x) => x.id == v[0].value
                              )[0].permissions;
                              let namePermissions = permissions
                                .map((v) => v.children)
                                .flat()
                                .filter((v) =>
                                  selectedRolePermissions.includes(v.name)
                                )
                                .map((v) => v.name)
                                .join(",");

                              setData({
                                ...data,
                                emailPreferences: namePermissions,
                                role:
                                  v && v.length ? v.map((v) => v.value) : [],
                              });
                            } else if (
                              v.length == 0 &&
                              (!isEdit ||
                                (isEdit && userDetails.role.includes("admin")))
                            ) {
                              setData({
                                ...data,
                                role: "",
                              });
                            } else {
                              setData({
                                ...data,
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* {user && user.role == "superadmin" && <div class="form_inner p-2">
                                    <label for="">Choose services </label>
                                    <div class="input_icons">
                                        <div class="w-100">
                                            <Select isMulti options={services} placeholder="Select Services" onChange={v => {
                                                setData({
                                                    ...data,
                                                    chosenServices: v && v.length ? v : []
                                                })
                                            }} />
                                        </div>
                                    </div>
                                </div>} */}
                </div>
              </div>
            </form>

            <div class="main_container mt-4">
              <div>
                <div class="accident">
                  <div class="accident_text">
                    <h5>User's Sites</h5>
                  </div>
                </div>
                <div id="wrapper" style={{ maxHeight: "300px" }}>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col" width="5%">
                          View
                        </th>
                        <th scope="col" width="8%">
                          Name
                        </th>
                        <th scope="col" width="8%">
                          Description
                        </th>

                        {!isProfile && <>
                          <th scope="col" width="8%">
                            Action
                          </th>
                        </>}
                      </tr>
                    </thead>
                    <tbody>
                      {userSites.map((d) => (
                        <tr>
                          <th
                            scope="row"
                            className="cursor-pointer"
                            style={{ color: "#005CB9" }}
                            onClick={() => {
                              history.push("/view-site/" + d.id);
                            }}
                          >
                            {d.id}
                          </th>
                          <td>{d.name}</td>
                          <td>{d.description}</td>
                          
                          {!isProfile && <>
                            <td>
                              {!isProfile && <>
                                <img
                                  src={Delete}
                                  alt=""
                                  onClick={() => {
                                    handleDeleteSite(d.id, d.name);
                                  }}
                                />
                              </>}
                            </td>
                          </>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {!isProfile && <>
                  <div>
                    <label>Add to Site</label>
                    <div className="row">
                      <div className="col-md-6">
                        <Autocomplete
                          value={selectedSite}
                          onChange={(event, newValue) => {
                            setSelectedSite(newValue);
                          }}
                          disablePortal
                          id="combo-box-demo"
                          options={sites.filter(
                            (v) => !userSites.map((v) => v.id).includes(v.id)
                          )}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Site" />
                          )}
                        />
                      </div>

                      <div className="col-md-6">
                        <Autocomplete
                          value={selectedRole}
                          onChange={(event, newValue) => {
                            setSelectedRole(newValue);
                          }}
                          disablePortal
                          id="combo-box-demo"
                          options={siteRoles}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Role" />
                          )}
                        />
                      </div>
                    </div>
                    <button
                      disabled={!selectedSite}
                      className="view_site_button text-white submit mt-2"
                      onClick={!selectedSite ? () => {} : () => addSiteUser()}
                    >
                      Add
                    </button>
                  </div>
                </>}
              </div>
            </div>
            <div class="main_container mt-4">
              <div>
                <div class="accident">
                  <div class="accident_text">
                    <h5>User's Departments</h5>
                  </div>
                </div>
                <div id="wrapper" style={{ maxHeight: "300px" }}>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col" width="5%">
                          View
                        </th>
                        <th scope="col" width="8%">
                          Name
                        </th>
                        <th scope="col" width="8%">
                          Description
                        </th>
                        
                        {!isProfile && <>
                          <th scope="col" width="8%">
                            Action
                          </th>
                        </>}
                      </tr>
                    </thead>
                    <tbody>
                      {userDepartments.map((d) => (
                        <tr>
                          <th
                            scope="row"
                            className="cursor-pointer"
                            style={{ color: "#005CB9" }}
                            onClick={() => {
                              history.push("/view-department/" + d.id);
                            }}
                          >
                            {d.id}
                          </th>
                          <td>{d.name}</td>
                          <td>{d.description}</td>

                          {!isProfile && <>
                            <td>
                              <img
                                src={Delete}
                                alt=""
                                onClick={() => {
                                  handleDeleteDepartment(d.id, d.name);
                                }}
                              />
                            </td>
                          </>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {!isProfile && <>
                  <div className="">
                    <label>Add to Department</label>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Autocomplete
                          value={selectedSite}
                          onChange={(event, newValue) => {
                            setSelectedDepartment(newValue);
                          }}
                          disablePortal
                          id="combo-box-demo"
                          options={departments.filter(
                            (v) => !userDepartments.map((v) => v.id).includes(v.id)
                          )}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Department" />
                          )}
                        />
                      </div>
                      <div className="col-md-6">
                        <Autocomplete
                          value={selectedDRole}
                          onChange={(event, newValue) => {
                            setSelectedDRole(newValue);
                          }}
                          disablePortal
                          id="combo-box-demo"
                          options={departmentRoles}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Role" />
                          )}
                        />
                      </div>
                    </div>
                    
                    <button
                      disabled={!selectedDepartment}
                      className="view_site_button text-white submit mt-2"
                      onClick={
                        !selectedDepartment ? () => {} : () => addDepartmentUser()
                      }
                    >
                      Add
                    </button>
                  </div>
                </>}
              </div>
            </div>

            <div class="signature">
              <div class="sign_btns">
                <div>
                  {!loading ? (
                    <button
                      class="submit"
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      {!isEdit ? "Create" : "Update"}
                    </button>
                  ) : (
                    <SubmitLoader />
                  )}
                  {userDetails.role.includes("admin") && id && !loading ? (
                    <button
                      className="submit"
                      onClick={() =>
                        handlePasswordReset(data.id, data.fullName, data.email)
                      }
                    >
                      Send Password Reset Email
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isEdit && (
          <div class="main_container mt-4">
            <div>
              <div class="accident">
                <div class="accident_text">
                  <h5>Email Preferences</h5>
                </div>
              </div>
              <form>
                <div class="form-1">
                  <div class="form justify-content-start">
                    <div class="form_inner p-2">
                      <div className="row w-100">
                        <div className="row mt-4">
                          <div className="col-10 mb-4">
                            <div className="d-flex justify-content-between">
                              <label className="p-2" for="">
                                {" "}
                                Permissions
                              </label>
                            </div>
                          </div>
                          <div className="col-2 mb-4">
                            <div className="d-flex justify-content-end">
                              <label className="p-2" for="">
                                {" "}
                                Select All
                              </label>

                              <div class="form-check form-switch">
                                <input
                                  class="sub-permission-check form-check-input form-check-input-color d-block"
                                  style={{ width: "50px" }}
                                  type="checkbox"
                                  role={`switch`}
                                  id={`flexSwitchCheckChecked`}
                                  checked={emailPreference.reduce(
                                    (aggregate, p) => aggregate && p.value,
                                    true
                                  )}
                                  onChange={(e) => {
                                    setEmailPref((d) =>
                                      d.map((p) => ({
                                        ...p,
                                        value: e.target.checked,
                                      }))
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {permissions.map((v, i) => (
                          <div className="row mt-4">
                            <h5 className="mb-4">{v.name}</h5>
                            {v.children
                              .filter((v) =>
                                emailPreference
                                  .map((v) => v.name)
                                  .includes(v.name)
                              )
                              .map((v) => (
                                <div className="col-md-6 col-xl-4 col-sm-6  mb-4">
                                  <div className="d-flex justify-content-between">
                                    <label for={`flexSwitchCheckChecked-${i}`}>
                                      {v.label}{" "}
                                    </label>
                                    <div class="form-check form-switch">
                                      <input
                                        class="sub-permission-check form-check-input form-check-input-color d-block"
                                        style={{ width: "50px" }}
                                        type="checkbox"
                                        role={`switch-${i}`}
                                        id={`flexSwitchCheckChecked-${i}`}
                                        checked={
                                          emailPreference.filter(
                                            (p) => p.name === v.name
                                          )[0].value
                                        }
                                        onChange={(e) => {
                                          setEmailPref((d) =>
                                            d.map((p) => {
                                              if (p.name === v.name) {
                                                return {
                                                  ...p,
                                                  value: e.target.checked,
                                                };
                                              } else {
                                                return p;
                                              }
                                            })
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="signature">
                <div class="sign_btns">
                  <div>
                    {!loading ? (
                      <button
                        class="submit"
                        disabled={loading}
                        onClick={handleEmailPreference}
                      >
                        Update
                      </button>
                    ) : (
                      <SubmitLoader />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
}

export default CreateUser