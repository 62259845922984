import axios from "./../libs/axios";

class SafetyService {
  getSafetyItems(param) {
    return axios.get(`safety/items`, param);
  }

  getSafetyItemsForSelect(param) {
    return axios.get(`/safety/items-for-select`, param);
  }

  getSafetyItemDetails(id) {
    return axios.get(`safety/items/${id}`);
  }

  createSafetyItem(payload) {
    return axios.post(`safety/items`, payload);
  }

  updateSafetyItem(id, payload) {
    return axios.post(`safety/items/${id}`, payload);
  }

  deleteSafetyItem(id, payload) {
    return axios.delete(`safety/items/${id}`);
  }

  getSafetyTemplates(param) {
    return axios.get(`safety/templates`, param);
  }

  createSafetyTemplate(payload) {
    return axios.post(`safety/templates`, payload);
  }

  getSafetyTemplateDetails(id) {
    return axios.get(`safety/templates/${id}`);
  }

  updateSafetyTemplate(id, payload) {
    return axios.put(`safety/templates/${id}`, payload);
  }

  deleteSafetyTemplate(id, payload) {
    return axios.delete(`safety/templates/${id}`);
  }
  
  restoreSafetyTemplate(id, payload) {
    return axios.post(`safety/undelete/templates/${id}`);
  }

  getTemplateSections(templateId, payload) {
    return axios.get(`/safety/templates/${templateId}/sections`);
  }

  getTemplateItems(templateId) {
    return axios.get(`/safety/templates/${templateId}/items`);
  }

  deleteTemplateItem(id) {
    return axios.delete(`/safety/templates/items/${id}`);
  }

  createTemplateSection(templateId, payload) {
    return axios.post(`/safety/templates/${templateId}/sections`, payload);
  }

  addItemToTemplate(templateId, payload) {
    return axios.post(`/safety/templates/${templateId}/items`, payload);
  }

  updateTemplateItemsOrder(templateId, updatedList) {
    return axios.put(`/safety/templates/${templateId}/items`, updatedList);
  }
  
  updateSortingForSectionItems(templateId, sortingOrder) {
    return axios.put(`/safety/templates/${templateId}/sectionOrder`, sortingOrder);
  }

  updateSafetyChecklist(id, payload) {
    return axios.put(`/update/safety-checklist/${id}`, payload);
  }

  createSafetyChecklist(payload) {
    return axios.post(`/add/safety-checklist`, payload);
  }

  getSafetyChecklistDetails(id) {
    return axios.get(`/safety-checklists/safety-checklist/details/${id}`);
  }

  getChecklistAnswers(id) {
    return axios.get(`/safety-checklists/answers/${id}`);
  }

  importCSV(payload) {
    return axios.post(`/safety-checklists/importItems`, payload);
  }
}

export default new SafetyService();
